import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
  Select,
  Button,
  Stack,
  Text,
  Grid,
  Icon,
  AspectRatio,
} from "@chakra-ui/react";
import Seo from "components/Seo";
import Container from "components/ui/Container";
import { Form, Formik, Field, useFormikContext } from "formik";
import { MdPhone, MdEmail, MdLocationPin } from "react-icons/md";
import { navigate } from "gatsby";
import axios from "axios";

const contactFormInitialValue = {
  pricingQuery: undefined,
  interested: "",
  name: "",
  country: "India",
  role: "",
  email: "",
  phone: "",
  companyName: "",
  position: "",
  message: "",
};

type QueryTypeFieldProps = {
  query?: string;
};

const QueryTypeField: React.FC<QueryTypeFieldProps> = props => {
  const { setFieldValue } = useFormikContext();

  React.useEffect(() => {
    if (props.query) {
      setFieldValue("pricingQuery", props.query);
    }
  }, [props.query]);

  return (
    <Field name="pricingQuery">
      {({ field, form }: { field: any; form: any }) => (
        <FormControl
          isInvalid={form.errors.name && form.touched.name}
          isRequired
        >
          <FormLabel htmlFor="pricingQuery">Get query for</FormLabel>
          <Select {...field} id="pricingQuery" placeholder="Get query for">
            <option value="Monthly">Monthly</option>
            <option value="Quarterly">Quarterly</option>
            <option value="Yearly">Yearly</option>
          </Select>
          <FormErrorMessage>{form.errors.name}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

const ContactUsPage: React.FC = () => {
  const [pricingQuery, setPricingQuery] = React.useState<string>();
  const [isDownloadBrochure, setIsDownloadBrochure] = React.useState(false);

  const brochureDownloadButtonRef = React.useRef<HTMLAnchorElement>(null);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const pricingQuery = queryParams.get("pricingQuery");
    const isFromDownloadBrochure =
      queryParams.get("action") === "download_brochure";
    setPricingQuery(pricingQuery || "");
    setIsDownloadBrochure(isFromDownloadBrochure);
  }, []);

  const handleFormSubmit = async (values: any, actions: any) => {
    try {
      await fetch(
        "https://aihousemailservice.onrender.com/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (isDownloadBrochure && brochureDownloadButtonRef.current) {
        brochureDownloadButtonRef.current.click();
      }

      navigate("/form-submit-success");
    } catch (e) {
      console.error(e);
      alert("There was an error while submitting the form. PLease try again");
    } finally {
      actions.setSubmitting(false);
    }

//     try {
//       const dataToSend = {
//         "personalizations": [
//           {
//             "to": [
//               {
//                 "email": "jasdhimaan99@gmail.com"
//               }
//             ]
//           }
//         ],
//         "from": {
//           "email": "contact@aihouseindia.com",
//           "name": 'AiHouse India'
//         },
//         "subject": `New form submission from ${values.name}`,
//         "content": [
//           {
//             "type": "text/html",
//             "value": `
// <div style="max-width: 500px; margin: 0 auto;">
// <h1 style="color: #dd6b20; font-size: 32px;">There is a new form submission on aihouseindia.com</h1>
// <div>
// <div>
//   <p style="font-weight: bold; margin: 0;">What are you interested in?</p>
//   <p style="margin: 0; margin-top: 5px;">${values.interested}</p>
// </div>
// <div style="margin-top: 20px;">
//   <p style="font-weight: bold; margin: 0;">Name</p>
//   <p style="margin: 0; margin-top: 5px;">${values.name}</p>
// </div>
// <div style="margin-top: 20px;">
//   <p style="font-weight: bold; margin: 0;">Country</p>
//   <p style="margin: 0; margin-top: 5px;">${values.country}</p>
// </div>
// <div style="margin-top: 20px;">
//   <p style="font-weight: bold; margin: 0;">What industry do you work in?</p>
//   <p style="margin: 0; margin-top: 5px;">${values.role}</p>
// </div>
// <div style="margin-top: 20px;">
//   <p style="font-weight: bold; margin: 0;">Email</p>
//   <p style="margin: 0; margin-top: 5px;">${values.email}</p>
// </div>
// <div style="margin-top: 20px;">
//   <p style="font-weight: bold; margin: 0;">Phone</p>
//   <p style="margin: 0; margin-top: 5px;">${values.phone}</p>
// </div>
// <div style="margin-top: 20px;">
//   <p style="font-weight: bold; margin: 0;">Company name</p>
//   <p style="margin: 0; margin-top: 5px;">${values.companyName}</p>
// </div>
// <div style="margin-top: 20px;">
//   <p style="font-weight: bold; margin: 0;">Position</p>
//   <p style="margin: 0; margin-top: 5px;">${values.position}</p>
// </div>
// <div style="margin-top: 20px;">
//   <p style="font-weight: bold; margin: 0;">Message</p>
//   <p style="margin: 0; margin-top: 5px;">${values.message}</p>
// </div>
// <div style="margin-top: 20px;">
//   <p style="font-weight: bold; margin: 0;">Pricing query</p>
//   <p style="margin: 0; margin-top: 5px;">${values.pricingQuery || "N/A"}</p>
// </div>
// </div>
// <p style="margin-top: 30px; font-size: 20px;"><strong>Thank you!</strong></p>
// </div>
//             `
//           }
//         ]
//       }

//       const response = await axios.post('https://api.sendgrid.com/v3/mail/send', dataToSend, {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer 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`,
//         },
//       });

//       console.log("Email Response", response);

//       if (isDownloadBrochure && brochureDownloadButtonRef.current) {
//         brochureDownloadButtonRef.current.click();
//       }

//       navigate("/form-submit-success");

//     } catch (error) {
//       console.error('Error making API request:', error);
//       alert("There was an error while submitting the form. PLease try again");

//     } finally {
//       actions.setSubmitting(false);
//     }
  };

  return (
    <Box py={10}>
      <Seo
        title="Contact With Our Team"
        description="Contact us to experience the AiHouse 3D interior design visualization and realistic renderings software for interior designers and furnishing brands with immersive experience. Aihouse is all-in-one interior and exterior design tools."
        keywords="interior design software, interior design rendering software, 3D interior and exterior design rendering software, online floor plan design software, cloud-based interior design and rendering software platform, online 4K rendering software"
      />

      <Container fluid>
        <Stack spacing={10}>
          <Stack spacing={1} textAlign="center">
            <Text as="h1" fontSize="5xl" fontWeight="bold">
              Contact Us
            </Text>

            <Text color="gray">
              {isDownloadBrochure
                ? "Please complete your information to download the brochure"
                : "Please complete your information and we will contact you within 1 working day."}
            </Text>
          </Stack>

          <Formik
            initialValues={contactFormInitialValue}
            onSubmit={handleFormSubmit}
          >
            {props => (
              <Form>
                <Stack
                  spacing={6}
                  p={6}
                  rounded="lg"
                  shadow="lg"
                  border="1px"
                  borderColor="gray.200"
                >
                  {pricingQuery ? (
                    <QueryTypeField query={pricingQuery} />
                  ) : null}

                  <Field name="interested">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                        isRequired
                      >
                        <FormLabel htmlFor="interested">
                          What are you interested in?
                        </FormLabel>
                        <Select
                          {...field}
                          id="interested"
                          placeholder="What are you interested in?"
                        >
                          <option value="all">All</option>
                          <option value="Designing & Rendering">
                            Designing & Rendering
                          </option>
                          <option value="Animations">Animations</option>
                          <option value="Brand Gallery">Brand Gallery</option>
                          <option value="Design to Manufacture System">
                            Design to Manufacture System
                          </option>
                        </Select>
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="name">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                        isRequired
                      >
                        <FormLabel htmlFor="name">Name</FormLabel>
                        <Input {...field} id="name" placeholder="Name" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="country">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                        isRequired
                      >
                        <FormLabel htmlFor="country">Country</FormLabel>
                        <Select {...field} id="country" placeholder="Country">
                          <option value="India">India</option>
                        </Select>
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="role">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                        isRequired
                      >
                        <FormLabel htmlFor="role">
                          What industry do you work in?
                        </FormLabel>
                        <Select
                          {...field}
                          id="role"
                          placeholder="What industry do you work in?"
                        >
                          <option value="Manufacturing">Manufacturing</option>
                          <option value="Furniture/Furnishing">
                            Furniture/Furnishing
                          </option>
                          <option value="Interior Design">
                            Interior Design
                          </option>
                          <option value="Architecture/Real Estate">
                            Architecture/Real Estate
                          </option>
                          <option value="Software/IT">Software/IT</option>
                          <option value="Education/Training">
                            Education/Training
                          </option>
                          <option value="Others">Others</option>
                        </Select>
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="email">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                        isRequired
                      >
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <Input
                          {...field}
                          id="email"
                          placeholder="Email"
                          type="email"
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="phone">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                        isRequired
                      >
                        <FormLabel htmlFor="phone">Phone</FormLabel>
                        <Input {...field} id="phone" placeholder="Phone" />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="companyName">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <FormLabel htmlFor="companyName">
                          Company name
                        </FormLabel>
                        <Input
                          {...field}
                          id="companyName"
                          placeholder="Company name"
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="position">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <FormLabel htmlFor="position">Position</FormLabel>
                        <Input
                          {...field}
                          id="position"
                          placeholder="Position"
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="message">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                        isRequired
                      >
                        <FormLabel htmlFor="messsage">Message</FormLabel>
                        <Textarea
                          {...field}
                          id="message"
                          placeholder="Message"
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Stack justifyContent="center" mt={4} direction="row">
                    <Button
                      colorScheme="orange"
                      isLoading={props.isSubmitting}
                      loadingText="Please wait..."
                      type="submit"
                      size="lg"
                    >
                      {pricingQuery
                        ? "Get Query"
                        : isDownloadBrochure
                          ? "Send & Download Brochure"
                          : "Send Message"}
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>

          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            gap={6}
          >
            <AspectRatio ratio={4 / 3}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14036.620703505228!2d77.0939932!3d28.4145742!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd9493a425d5abd44!2sAiHouse%20India!5e0!3m2!1sen!2sin!4v1638259859853!5m2!1sen!2sin"
                className="border-0 w-full h-auto"
                allowFullScreen={false}
                loading="lazy"
              />
            </AspectRatio>

            <Stack spacing={6}>
              <Text as="h3" fontWeight="bold" fontSize="3xl">
                Corporate Office
              </Text>

              <Stack direction="row" spacing={4}>
                <Icon as={MdLocationPin} color="black" w={8} h={8} />
                <Text>
                  Emaar Digital Greens, Tower-B, 903, Golf Course Road
                  Extension, Sector-61, Gurugram, Haryana, India 122102
                </Text>
              </Stack>

              <Stack direction="row" spacing={4}>
                <Icon as={MdPhone} color="black" w={8} h={8} />
                <Text>+91 9311481188</Text>
              </Stack>

              <Stack direction="row" spacing={4}>
                <Icon as={MdEmail} color="black" w={8} h={8} />
                <Text>contactaihouseindia@gmail.com</Text>
              </Stack>
            </Stack>
          </Grid>
        </Stack>
      </Container>

      <a
        ref={brochureDownloadButtonRef}
        rel="noreferrer"
        target="_blank"
        href="/files/AiHouse India E-Brochures.pdf"
        hidden
      />
    </Box>
  );
};

export default ContactUsPage;
